<script>
import AppIcon from "@/components/atoms/AppIcon.vue";
import AppCard from "@/components/atoms/AppCard.vue";
import format from "@/helpers/format";

export default {
  name: "MemberShowCard",
  components: { AppIcon, AppCard },
  data() {
    return {};
  },
  props: {
    id: String,
    name: {
      type: String,
      default: "",
    },
    contact: {
      type: Object,
      default: () => {},
    },
    birthday: {
      type: String,
      default: "",
    },
    academyInfo: Object,
  },
  computed: {
    formatedBirthday: function () {
      return format.date(this.birthday);
    },
    formatedRegistry: function () {
      return format.date(this.academyInfo.register);
    },
    formatedName: function () {
      return format.name(this.name);
    },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <app-card customStyle="padding: 16px; width: 100%;">
    <div class="member-show-name">
      <p>{{ formatedName }}</p>
    </div>
    <span class="member-show-info">
      <div class="member-show-date">
        <p>
          <small>
            <app-icon
              color="#797979"
              size="22"
              style="transform: translateY(-4px)"
              icon="mdi-account-check-outline"
            />
            {{ formatedRegistry }}</small
          >
        </p>
        <p>
          <small>
            <app-icon
              color="#797979"
              style="transform: translateY(-4px)"
              size="22"
              icon="mdi-cake-layered"
            />
            {{ formatedBirthday }}</small
          >
        </p>
      </div>
      <div
        v-if="contact.phone.length > 0 || contact.email.length > 0"
        class="member-show-contact"
      >
        <p v-if="contact.phone.length > 0" @click="emit('whatsapp')">
          <small style="color: #1a83ff">
            <app-icon color="#797979" size="22" icon="mdi-whatsapp" />
            {{ contact.phone }}
          </small>
        </p>
        <p v-if="contact.email.length > 0" @click="emit('email')">
          <small style="color: #1a83ff">
            <app-icon color="#797979" size="22" icon="mdi-email-outline" />
            {{ contact.email }}</small
          >
        </p>
        <p v-if="contact.phone.length > 0" @click="emit('phone')">
          <small style="color: #1a83ff">
            <app-icon color="#797979" size="22" icon="mdi-phone" />
            {{ contact.phone }}
          </small>
        </p>
      </div>
    </span>
  </app-card>
</template>

<style lang="scss" scoped>
.member-show-name {
  text-align: left;
}

.member-show-info {
  margin: 0.5vw 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.member-show-date,
.member-show-contact {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px 0;
  gap: 12px;
}
.member-show-date {
  gap: 32px;
  flex-wrap: wrap;
  flex-direction: row;
}
.member-show-contact {
  border-top: 1px solid #eaeaea;
  padding-right: 16%;
  padding-bottom: 8px;

  p {
    padding-bottom: 8px;
    color: #1a83ff;
  }
}
</style>
