<script>
import AppAvatar from "@/components/atoms/AppAvatar.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import AppGraduationBadge from "@/components/atoms/AppGraduationBadge.vue";
export default {
  name: "MemberShowAvatar",
  components: {
    AppAvatar,
    AppButton,
    AppGraduationBadge,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    graduation: {
      type: Object,
      default: undefined,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emit(event) {
      this.$emit(event);
    },
  },
};
</script>

<template>
  <div id="member-show-profile" style="width: 144px; margin: 0 auto">
    <span style="width: 85px; margin: 0 auto">
      <app-avatar
        customStyle="border-radius: 20px 5px 0px 0px; margin: 0 auto !important;"
        customHeight="80px"
        customWidth="85px"
        :image="image"
      />
      <app-graduation-badge
        :graduation="graduation.graduation"
        :color="graduation.color"
        :text-color="graduation.textColor"
        customStyle="border-radius: 0px 0px 5px 20px; width: 85px;"
      />
    </span>
    <span class="member-card-buttons">
      <app-button
        type="icon"
        @click="emit('clickEdit')"
        size="50px"
        borderRadius="5px 20px 5px 5px"
        color="#FFFFFF"
        customStyle="width: 55px !important; min-width: 55px !important;"
        icon="mdi-pencil-outline"
      />
      <app-button
        type="icon"
        @click="emit('clickActive')"
        size="50px"
        borderRadius="5px 5px 20px 5px"
        color="#FFFFFF"
        icon="mdi-sleep"
        :iconColor="isActive ? 'green' : 'maroon'"
        customStyle="width: 55px !important; min-width: 55px !important;"
      />
    </span>
  </div>
</template>

<style lang="scss">
#member-show-profile {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.member-card-buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
