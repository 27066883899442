<script>
  import { mapGetters } from "vuex";
  import generateRandomString from "@/helpers/generateRandomString";
  import MemberShowTemplate from "../templates/MemberShowTemplate.vue";
  export default {
    components: { MemberShowTemplate },
    name: "MemberShowPage",
    props: {
      id: String,
    },
    computed: {
      member: function () {
        return this.$store?.state?.user?.users.filter(
          (user) => user.id === this.id
        )[0];
      },
      ...mapGetters({
        currentAcademy: "academy/currentAcademy",
      }),
    },
    methods: {
      cancel() {
        this.$router.push("/");
      },
      oneTimeLink() {
        let key = generateRandomString(28);
        // TODO: create function to store key in academy table]
        // store key
        // create function that consumes stored hash

        let myUrl =
          "https://app.dojosei.com/academy/" +
          this.currentAcademy.id +
          "/one-time-access/" +
          key;

        let updatedAcademy = this.currentAcademy;

        let accessCodes = { ...this.currentAcademy.accessCodes };
        accessCodes[key] = this.id;

        let payload = {};

        updatedAcademy.accessCodes = accessCodes;

        payload.academyId = this.currentAcademy.id;
        payload.academy = updatedAcademy;

        navigator.clipboard.writeText(myUrl);

        this.$store.dispatch("academy/createOneTimeAccess", payload, {
          root: true,
        });
        // make a dialog that shows the url
        // with copy and share buttons
        /* this.$router.push("/academy/" + this.currentAcademy.id + "/one-time-access/" + key); */
      },
      handleContactClick(payload) {
        switch (payload) {
          case "whatsapp":
            window.open(
              "https://wa.me/" + this.cleanNumber(this.member.contact.phone),
              "_blank" // <- This is what makes it open in a new window.
            );
            break;
          case "email":
            location.href = `mailto:${this.member.contact.email}`;
            break;
          case "phone":
            location.href =
              "tel:" + this.cleanNumber(this.member.contact.phone);
            break;
        }
      },
      cleanNumber(number) {
        return number.replace(/[- )(]/g, "");
      },
      billingHistoryClick() {
        this.$router.push("/member/" + this.member.id + "/billing/history");
      },
    },
  };
</script>

<template>
  <member-show-template
    :member="member"
    @cancel="cancel"
    @oneTimeLink="oneTimeLink"
    @contactClick="handleContactClick"
    @billingHistoryClick="billingHistoryClick"
  />
</template>
