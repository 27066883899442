<script>
export default {
  name: "AppGraduationBadge",
  data() {
    return {};
  },
  props: {
    graduation: {
      type: String,
      default: "--",
    },
    color: {
      type: String,
      default: "#d0d4d5",
    },
    textColor: {
      type: String,
      default: "#000000",
    },
    customStyle: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <div class="user-grad">
    <v-chip :style="customStyle" :color="color" small :input-value="true">
      <p :style="'color: ' + textColor + ';'">{{ graduation }}</p>
    </v-chip>
  </div>
</template>

<style lang="scss">
.user-grad {
  grid-area: grad;
  width: 96px;

  .v-chip {
    padding: 0 !important;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    // background-color: #f2f2f2;
    font-size: 12px;
    font-weight: bold;

    .v-chip__content {
      margin: 0 auto !important;
    }
  }
}
</style>
