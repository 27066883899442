<script>
import TheInnerToolbar from "../organisms/TheInnerToolbar.vue";
import MemberShowAvatar from "@/components/molecules/MemberShowAvatar.vue";
/*   import AppButton from "@/components/atoms/AppButton.vue";
 */ import MemberShowBillingCard from "@/components/molecules/MemberShowBillingCard.vue";
import MemberShowCard from "@/components/molecules/MemberShowCard.vue";
import { getGraduationList } from "@/helpers/get-graduation-list";
import moment from "moment";
import { mapGetters } from "vuex";
import AppDialog from "@/components/atoms/AppDialog.vue";
import format from "@/helpers/format";
import MemberShowAddressCard from "@/components/molecules/MemberShowAddressCard.vue";
/* import MemberShowGraduationCard from "@/components/molecules/MemberShowGraduationCard.vue"; */

export default {
  name: "MemberShowTemplate",
  components: {
    MemberShowAvatar,
    MemberShowCard,
    MemberShowAddressCard,
    MemberShowBillingCard,
    /* MemberShowGraduationCard, */
    TheInnerToolbar,
    AppDialog,
    /*  AppButton, */
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      currentGraduation: {
        graduation: undefined,
        color: undefined,
        textColor: undefined,
      },
      graduationList: [],
    };
  },
  mounted() {
    this.graduationList = getGraduationList(this.genre);
    this.getGraduationObject();
  },
  props: {
    member: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formatedName: function () {
      return format.name(this.member.name);
    },
    genre: function () {
      return this.$store.state.academy.currentAcademy.genre;
    },
    ...mapGetters({
      currentAcademy: "academy/currentAcademy",
      billingList: "billing/billingList",
    }),
    isActive: function () {
      return this.member.academies[this.currentAcademy.id].isActive;
    },
  },
  methods: {
    editProfile() {
      this.$router.push("/member/" + this.member.id + "/edit");
    },
    editActive() {
      this.dialog = true;
    },
    confirmEditActive() {
      this.dialog = false;
      let academy = {};
      academy.isActive = this.member.academies[this.currentAcademy.id].isActive;
      academy.register = this.member.academies[this.currentAcademy.id].register;
      academy.isActive = !academy.isActive;
      let academies = { ...this.member.academies };

      academies[this.currentAcademy.id] = academy;
      this.$store.dispatch("user/updateActive", {
        id: this.member.id,
        member: { academies: academies },
      });
      /* call function to edit active status */
    },
    cancelEditActive() {
      this.dialog = false;
    },
    oneTimeLink() {
      this.$emit("oneTimeLink");
    },
    cancel() {
      this.$emit("cancel");
    },
    oneTimeDialog() {
      this.dialog2 = true;
    },
    oneTimeConfirm() {
      this.oneTimeLink();
    },
    oneTimeCancel() {
      this.dialog2 = false;
    },
    getGraduationObject() {
      if (
        this.member.graduation != undefined &&
        typeof this.member.graduation === "number"
      ) {
        this.currentGraduation = this.graduationList.find(
          (item) => item.value === this.member.graduation
        );
      }
    },
    contactClick(payload) {
      this.$emit("contactClick", payload);
    },
    billingHistoryClick() {
      this.$emit("billingHistoryClick");
    },
    getMemberBilling(memberId) {
      let memberBilling = this.billingList.filter(
        (billing) => billing.memberId === memberId
      );
      memberBilling.sort((a, b) => moment(b.reference) - moment(a.reference));
      return memberBilling[0];
    },
  },
};
</script>

<template>
  <div class="profile-container">
    <the-inner-toolbar
      style="margin-bottom: 32px"
      icon="mdi-share-variant-outline"
      title="Detalhes"
      subtitle="Membros"
      @click="oneTimeDialog"
      @cancel="cancel"
    />
    <!-- <app-button
      customStyle="position: fixed !important; top: 98px !important; right: 32px !important;"
      type="icon"
      @click="oneTimeLink"
      size="50px"
      color="#FFFFFF"
      icon="mdi-share-variant"
    /> -->
    <member-show-avatar
      image=""
      :graduation="currentGraduation"
      :isActive="isActive"
      @clickEdit="editProfile"
      @clickActive="editActive"
    />
    <!-- botão editar -->
    <span id="member-info-container">
      <member-show-card
        :id="member.id"
        :academyInfo="member.academies[currentAcademy.id]"
        :birthday="member.personal.birthday"
        :contact="member.contact"
        :name="member.name"
        @click="contactClick"
      />
      <member-show-billing-card
        v-if="getMemberBilling(member.id) != undefined"
        @click="billingHistoryClick"
        :billing="getMemberBilling(member.id)"
      />
      <!-- <member-show-graduation-card :graduation="currentGraduation" /> -->
      <member-show-address-card
        v-if="member.address.cep.length > 0"
        :address="member.address"
      />
    </span>
    <app-dialog
      :dialog="dialog"
      title="Confirmação"
      :text="
        member.academies[currentAcademy.id].isActive
          ? 'O usuário está Ativo. Deseja realmente Inativar esse usuário?'
          : 'O usuário está Inativo. Deseja realmente Ativar esse usuário?'
      "
      buttonCancel="Cancelar"
      buttonConfirm="Confirmar"
      @clickConfirm="confirmEditActive"
      @clickCancel="cancelEditActive"
    />
    <app-dialog
      :dialog="dialog2"
      title="Gerar Link"
      text="Ao clicar em 'Confirmar' será gerado um link de acesso único através do qual o membro poderá editar seu próprio cadastro. O link será copiado automaticamente para a área de transferência."
      buttonCancel="Cancelar"
      buttonConfirm="Confirmar"
      @clickConfirm="oneTimeConfirm"
      @clickCancel="oneTimeCancel"
    />
  </div>
</template>

<style lang="scss" scoped>
.profile-container {
  min-height: 100vh !important;
  min-width: 100% !important;
  padding: 16px;

  @media screen and (min-width: 1200px) {
    padding-top: 5vw;
  }

  h3 {
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    padding: 0 0.6em 24px 0.6em;
  }
}

#member-info-container {
  width: 100%;
  display: flex;
  padding-top: 24px;
  gap: 16px 32px;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    width: 50%;
    min-width: 700px;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 48px;
    * {
      flex: 0 0 calc(50% - 16px);
    }
  }
}
</style>
