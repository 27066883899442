<script>
import AppIcon from "@/components/atoms/AppIcon.vue";
import AppCard from "@/components/atoms/AppCard.vue";

export default {
  name: "AppExpandableCard",
  components: { AppIcon, AppCard },
  data() {
    return {
      maxHeight: "116px",
      open: false,
      icon: "mdi-chevron-down",
    };
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    customHeight: String,
  },
  methods: {
    click() {
      this.open = !this.open;
      if (this.open) {
        this.maxHeight = "100vw";
        setTimeout(() => (this.icon = "mdi-chevron-up"), 400);
      } else {
        this.maxHeight = "116px";
        setTimeout(() => (this.icon = "mdi-chevron-down"), 400);
      }
    },
  },
};
</script>

<template>
  <app-card
    :customStyle="
      'max-height: ' +
      maxHeight +
      '; padding: 16px; overflow: hidden; width: 100%;'
    "
    @click="click"
    class="app-expandable-card"
  >
    <div>
      <p>{{ name }}</p>
    </div>
    <app-icon
      color="#1C1B1F"
      size="48"
      :icon="icon"
      customStyle="position: absolute; top: calc(50% - 24px); right: 4px;"
    />
    <slot />
  </app-card>
</template>

<style lang="scss" scoped>
.app-expandable-card {
  transition: max-height ease-in-out 0.5s;
}
</style>
