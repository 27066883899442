<script>
export default {
  name: "AppAvatar",
  data() {
    return {};
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    customHeight: String,
    customWidth: String,
    customStyle: String,
  },
};
</script>

<template>
  <div>
    <v-list-item-avatar
      color="#BECEE2"
      class="avatar"
      :width="customWidth"
      :height="customHeight"
      :style="customStyle"
    >
      <img v-if="image.length > 0" :src="image" />
    </v-list-item-avatar>
  </div>
</template>

<style lang="scss">
.avatar {
  margin: 0 !important;
  background-image: url("../../assets/profile-image.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center 70%;
  background-size: 56px;
  background-blend-mode: screen;
}
</style>
