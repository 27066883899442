<script>
import AppIcon from "@/components/atoms/AppIcon.vue";
import AppCard from "@/components/atoms/AppCard.vue";
import format from "@/helpers/format";

export default {
  name: "MemberShowBillingCard",
  components: { AppIcon, AppCard },
  data() {
    return {};
  },
  props: {
    billing: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formatedDate: function () {
      return format.date(this.billing.date);
    },
    formatedReference: function () {
      return format.reference(this.billing.reference);
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<template>
  <span id="member-show-billing-container" @click="click">
    <app-card
      id="member-show-billing"
      customStyle="padding: 16px; width: 100%; border-radius: 20px 5px 5px 20px;border: 1px solid rgba(148, 172, 207, 0.4) !important;"
    >
      <div class="member-show-name">
        <p>Histórico de Pagamentos</p>
      </div>

      <span class="member-show-info">
        <div class="member-show-date">
          <p>
            <small
              ><app-icon color="#797979" size="22" icon="mdi-calendar-today" />
              {{ formatedDate }}
            </small>
          </p>
          <p>
            <small>
              <app-icon
                color="#797979"
                size="22"
                icon="mdi-calendar-check-outline"
              />
              {{ formatedReference }}</small
            >
          </p>
        </div>
      </span>
    </app-card>

    <app-card
      id="member-show-billing-button"
      customStyle="padding: 16px; border-radius: 5px 20px 20px 5px;border: 1px solid rgba(148, 172, 207, 0.4) !important;"
    >
      <app-icon
        color="#1C1B1F"
        size="48"
        icon="mdi-chevron-right"
        customStyle="position: absolute; top: calc(50% - 24px); transform: translateX(-12px); "
      />
    </app-card>
  </span>
</template>

<style lang="scss">
#member-show-billing-container {
  display: flex;
  gap: 4px;
  flex-direction: row;
}
#member-show-billing-button {
  width: 56px;
  min-width: 56px;
}
#member-show-billing {
  max-height: 136px;
}

.member-show-name {
  text-align: left;
}

.member-show-info {
  margin: 0.5vw 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.member-show-date {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  padding-right: 25%;
}
</style>
