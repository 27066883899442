<script>
export default {
  name: "AppIcon",
  data() {
    return {};
  },
  props: {
    color: String,
    size: String,
    icon: String,
    customStyle: String,
  },
};
</script>

<template>
  <v-icon :color="color" :size="size" :style="customStyle">{{ icon }}</v-icon>
</template>

<style module></style>
