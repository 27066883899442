<script>
import AppExpandableCard from "../atoms/AppExpandableCard.vue";
import AppIcon from "../atoms/AppIcon.vue";

export default {
  name: "MemberShowAddressCard",
  components: { AppIcon, AppExpandableCard },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formattedAddress: function () {
      return this.address.street.length > 0 && this.address.number.length > 0
        ? this.address.street + ", n" + this.address.number
        : "";
    },
  },
};
</script>

<template>
  <app-expandable-card name="Endereço">
    <div class="member-show-address">
      <p>
        <small
          ><app-icon color="#797979" size="22" icon="mdi-map-marker-outline" />
          {{ formattedAddress }}
          {{ address.complement }}
        </small>
      </p>
      <p>
        <small
          ><app-icon color="#797979" size="22" icon="mdi-home-city-outline" />
          {{ address.neighborhood }}
        </small>
      </p>
      <p>
        <small
          ><app-icon
            color="#797979"
            size="22"
            icon="mdi-city-variant-outline"
          />
          {{ address.city }}
        </small>
      </p>
      <p>
        <small
          ><app-icon color="#797979" size="22" icon="mdi-map-outline" />
          {{ address.state }}
        </small>
      </p>
      <p>
        <small
          ><app-icon color="#797979" size="22" icon="mdi-crosshairs-gps" />
          {{ address.cep }}
        </small>
      </p>
    </div>
  </app-expandable-card>
</template>

<style lang="scss" scoped>
/* .app-expandable-card {
  height: 100px;
} */

.member-show-address {
  p {
    padding: 4px 0;
    &:first-child {
      padding-top: 8px;
    }
  }
}
</style>
